section.hero-section {
    min-height:400px;
    height: 857px;

    .mod {
        min-height:400px;
        height: 857px;

        .display-1 {
            letter-spacing: 0.15em;
        }
    }
    @media screen and (max-width: 1600px) {
        & {
            height: 620px;
        }
        .mod {
            height: 620px;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            height: 63vw;
        }
        .mod {
            height: 63vw;
        }
    }
}