section.tagline-section {
    background-color: $primary;

    @media screen and (max-width: 1300px) {
        [class*="btn"] {
            max-width: 265px !important;
        }
    }
    @media screen and (max-width: 576px) {
        .contact-info-col {
            margin-right: 4vw;
        }
    }
    .top {
        font-family: eb-garamond, serif;
        letter-spacing: 0.02em;
        font-size: 28px;

        .brdr {
          border-right: 1px solid rgba(0,57,117,.2);
          font-size: 26px;
        }
        br {
          display: none;
        }
        .social {
            font-size: 21px;
        }
    }
  }