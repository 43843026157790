body section.ctas .mod a:hover {
    box-shadow: none;
}
body .btn {
    line-height: 1;
}
.btn {
    padding: 26px 48px !important;
    font-size: 19px !important;
}
footer.foot .btm {
    color: #b5b5b5 !important;
}
footer.foot .btm a {
    color: #c8a545 !important;
}

.modal-container {
    .modal.show {
        background: rgba(0,0,0,.75);
        .modal-dialog {
            width: 45vw !important;
            max-width: none;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
        }
    }
    .modal-body {
        padding: 0;
        margin-bottom: -11px;
    }
    iframe {
        border: none;
        aspect-ratio: 1536/536;
    }
    .modal-header {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        border: none;
    }
    .modal-header .close {
        background-color: transparent;
        color: #ffffff;
        border: none;
        font-size: 36px;
    }
}
.finance-button {
    position: fixed; bottom: 0; left: 100px; z-index: 9;
    transform: unset;
    width: unset;
}
.finance-button .btn {
    width: unset;
}

.finance-button {
    position: fixed; bottom: 0; left: 100px; z-index: 9;
    transform: unset;
    width: unset;
}
.finance-button .btn {
    width: unset;
}
@media (max-width: 992px) {
    .finance-button {
        width: 100%;
        left: 0;
    } 
    .finance-button .btn {
        padding: 18px 35px !important;
        width: 100%;
    }
    .services-section .panel-image {
        background-image: url('/prositesStyles/StyleLibrary/6729/images/servicesbg-mobile.webp') !important;
    }
    .modal-container {
        .modal.show {
            background: rgba(0,0,0,.75);
            .modal-dialog {
                width: 45vw !important;
                max-width: none;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%) !important;
                transform: translate(-50%, -50%) !important;
            }
        }
    }
    .modal-body {
        padding: 0;
        margin-bottom: -11px;
    }
    iframe {
        border: none;
        aspect-ratio: 1536/536;
    }
    .modal-header {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        border: none;
    }
    .modal-header .close {
        background-color: transparent;
        color: #ffffff;
        border: none;
        font-size: 36px;
    }
    .modal-container {
        .modal.show {
            background: rgba(0,0,0,.75);
                .modal-dialog {
                width: 80vw !important;
            }
        }
    }
    .hero-section .h1 {
        font-size: 50px!important;
    }
}