
section.services-section {
    background-color: rgba($color: #E5EAEF, $alpha: 0.50);

    .boxes .wrap {
        min-height: 300px;
    }
    .boxes h5 {
        font-family: "omnes-variable", sans-serif;
        font-size: 30px;
        padding: 0 10px;
        letter-spacing: 0;
        text-transform: capitalize;
        color: #415276;
        font-weight: 400;
        // -webkit-transition: -webkit-transform 0.35s;
        // transition: transform 0.35s;
        // -webkit-transform: translate3d(0,65%,0);
        // transform: translate3d(0,65%,0);
        min-height: 72px;
    }
    .boxes a:hover h5 {
        color: #FFFFFF;
        // -webkit-transform: translate3d(0,0,0);
        // transform: translate3d(0,0,0);
    }
    .boxes a:hover .wrap {
        background-color: $primary !important;
    }
    
}